<template>
  <v-container fluid>
    <!-- <v-container fluid> -->
      <!-- <global-filterMenuNew @submit-pressed="makeCall"/> -->
    <v-row>
      <global-EventFilterMenuTransactions header='Transaction'/>
    </v-row>

    <v-row>        
      <v-col
        cols="12"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-file-table </v-icon>
            Transactions
            <v-spacer/>
            <v-btn small class="mr-1">
              Generate XLXS Report
            </v-btn>
            <v-btn small @click="createPDF">
              Generate tax invoice
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0">
            <!-- TABLE -->
            <!-- <tables-simple
              :tableHeadings="topStalls.headings"
              :tableDataProperties="topStalls.dataProperties"
              :tableData="topStalls.data"
              :ranks="true"
            /> -->
            
            <v-data-table
              v-model="selectedTransaction"
              :headers="headers"
              :items="formattedTransactions"
              single-select
              item-key="TransactionID"
              show-select
              class="elevation-5"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import {
  mapActions,
  mapState
} from 'vuex'

export default {
  data: () => ({
    selectedTransaction: {},
    headers: [
      { text: "Amount", value: "Amount"},
      { text: "CardDiscountSurchargeTotal", value: "CardDiscountSurchargeTotal"},
      { text: "EventId", value: "EventId"},
      { text: "EventName", value: "EventName"},
      { text: "Items", value: "Items"},
      { text: "OrderNumber", value: "OrderNumber"},
      { text: "ProductDiscountSurchargeTotal", value: "ProductDiscountSurchargeTotal"},
      { text: "StallHolderId", value: "StallHolderId"},
      { text: "StallHolderName", value: "StallHolderName"},
      { text: "StallName", value: "StallName"},
      { text: "StallUserName", value: "StallUserName"},
      { text: "Stan", value: "Stan"},
      { text: "Status", value: "Status"},
      { text: "TerminalID", value: "TerminalID"},
      { text: "TerminalNumber", value: "TerminalNumber"},
      { text: "TimeCreated", value: "TimeCreated"},
      { text: "TransactionID", value: "TransactionID"},
      { text: "Type", value: "Type"}
    ]
  }),
  created() {
    this.getTransactions()
    console.log(this.transactionsTransactions)
  },
  filters: {
    currency(value) {
      var formatter = new Intl.NumberFormat('en-AU',{
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      return '$' + formatter.format(value);
    },
    number(value) {
      var formatter = new Intl.NumberFormat('en-AU',{
        maximumFractionDigits: 0
      })
      return formatter.format(value);
    }
  },
  computed: {
    ...mapState('filter', ['globalEventFilter', 'globalYearFilter']),
    ...mapState('dashboard', ['transactionsTransactions']),
    formattedTransactions() {
      if (!this.transactionsTransactions) return [];
      return this.transactionsTransactions.map(transaction => ({
        ...transaction,
        Amount: transaction.Amount !== undefined ? this.$options.filters.currency(transaction.Amount) : 'N/A',
        Items: transaction.Items !== undefined ? this.$options.filters.number(transaction.Items) : 'N/A'
      }));
    }
  },
  methods: {
    ...mapActions('dashboard', ['getTransactions']),
    makeCall() {
      console.log("HELLO WORLD")
    },
    createPDF() {
      console.log(this.selectedTransaction[0])
      this.$pdfEditor.createPdf("PDF-NAME-ID", this.selectedTransaction[0])
      // this.$pdfEditor.createPdf("PDF-NAME-ID", {
      //   event: this.globalEventFilter,
      //   stallHolder: "Some stall holder",
      //   stall: "Some stall",
      //   abn: "ABN:12 123 123 123",
      //   transaction: 'f06664c95a1a',
      //   terminal: "1",
      //   terminalId: "1231245124",
      //   date: "",
      //   time: "12:59:32"
      // })
    }
  }
}
</script>

<style scoped>
h1{
  color: #a09c94;
  font-family: sans-serif;
}
</style>